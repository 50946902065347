<script lang="ts" setup>
import { ref, watch } from "vue";
    const props = defineProps({
    title: {
        type: String,
        required : true
    },
    body: {
        type: String,
        required : true
    },
    cancelLabel: {
        type: String,
        default: () => "Annuleer",
    },
    okLabel: {
      type: String,
      default: () => "Ok",
    },
    deleteLabel: {
      type :String,
      default: () => "Verwijder",
    },
    modalOpen: {
        type: Boolean,
        default: false,
    },
    persistent: {
        type: Boolean,
        reqruied : true,
        default: false,
    },
    showOk : {
      type: Boolean,
      required : false,
      default: false,
    },
    showDelete : {
      type: Boolean,
      required : false,
      default: false,
    }
});

const isOpen = ref(false);
const emit = defineEmits(['onDialogResult'])

watch(() => props.modalOpen, async () => {
  isOpen.value = props.modalOpen;
});

function onClickCancelButton() {
  emit("onDialogResult", "CANCEL")
}

function onClickDeleteButton(){
  emit("onDialogResult", "DELETE")
}

function onClickOkButton() {
  emit("onDialogResult", "OK")
}

function onClickOutSide(){
    if (props.modalOpen){
        emit("onDialogResult", "CLOSE")
    }
}
</script>

<template>
    <v-dialog v-model="isOpen" scrollable :persistent="props.persistent" max-width="500">
      <v-card class="mx-auto" v-if="isOpen" v-click-outside="onClickOutSide">
        <v-card-title>
          <header class="app-welcome-message">
            <h2>{{props.title}}</h2>
          </header>
          <v-spacer></v-spacer>     
        </v-card-title>
        <v-card-text>
          <v-container class="register-form">
            <v-row>
              <v-col cols="12">
                <div class="form-group">
                  <label for="party-add-logo">{{props.body}}</label>
                  
                </div>
              </v-col>
            </v-row>
            
          </v-container>
          <!-- <v-card-actions class="justify-space-between"> -->
            <v-card-actions>
            <v-btn class="primary" v-if="props.showOk" v-on:click="onClickOkButton"> <v-icon  dark left> mdi-check </v-icon> {{ props.okLabel }} </v-btn> 
            <v-btn class="error" v-if="props.showDelete" v-on:click="onClickDeleteButton"> <v-icon  dark left> mdi-delete </v-icon> {{ props.deleteLabel }} </v-btn> 
            <v-spacer/>
            <v-btn v-on:click="onClickCancelButton">  <v-icon  dark left> mdi-cancel </v-icon>{{ props.cancelLabel }} </v-btn>
            </v-card-actions>
        </v-card-text>
      </v-card>
    </v-dialog>
  </template>