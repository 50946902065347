<script lang="ts" setup>
import { ref, computed, watch, getCurrentInstance } from "vue";
import { getRegisterUsageYearly, addRegisterYearUsage, delRegisterYearUsage, patchRegisterYearUsage, severityCode } from "@/services/api/energy.api";
import useDateTimeFormatter from "@/composables/formatDateTime.js";
import { ValidationObserver, ValidationProvider, setInteractionMode } from "vee-validate";
import AlertOkCancel from "../general/AlertOkCancel.vue";

const { formatDateUtcDT } = useDateTimeFormatter();

setInteractionMode("eager");

const props = defineProps({
  registerId: {
    type: String,
  },
  unitType: {
    type: String,
  },
});

const usages = ref([]);
const currentEdit = ref(undefined);
const isSaving = ref(false);

const headers = computed(() => {
  return [
    { text: "", value: "actions", sortable: false, width: 20 },
    { text: "Jaar", value: "year" },
    { text: `Waarde ${props.unitType}`, value: "value" },
    { text: "Bewerker", value: "modifiedBy" },
    { text: "Datum", value: "modifiedDate" },
    { text: "", value: "confirm", sortable: false, width: 20 },
    { text: "", value: "cancel", sortable: false, width: 20 },
    { text: "", value: "delete", sortable: false, width: 20 },
  ];
});

watch(
  () => props.registerId,
  () => {
    if (props.registerId) {
      fetchData();
    }
  },
  { immediate: true }
);

//hack voor vue 2.7
const proxy = getCurrentInstance().proxy;
const modalDeleteOpen = ref(false);
const modalDeleteBody = ref("");

async function fetchData() {
  const response = await getRegisterUsageYearly(props.registerId);
  if (response.severity <= severityCode.warning) {
    console.log("RegisterUsageYearlyDetails, fetchData ", props.registerId, response);
    response.data.sort((a, b) => b.year - a.year);
    usages.value = response.data;
  } else {
    proxy.$toaster.error("Ophalen van de data is mislukt! " + response.error);
  }
}

function addUsage() { 
  currentEdit.value = { index: 0, id: "new" };
  usages.value.splice(0, 0, currentEdit.value);
}

async function editRow(id) {
  const itemIndex = usages.value.findIndex((x) => x.id == id);
  if (itemIndex >= 0) {
    currentEdit.value = { index: itemIndex, id: usages.value[itemIndex].id, year: usages.value[itemIndex].year, value: usages.value[itemIndex].value };
  }
}

async function cancelRow(id) {
  const itemIndex = usages.value.findIndex((x) => x.id == id);
  if (currentEdit.value.id === "new") {
    usages.value.splice(0, 1);
  } else {
    usages.value[itemIndex].year = currentEdit.value.year;
    usages.value[itemIndex].value = currentEdit.value.value;
  }
  currentEdit.value = undefined;
}

function deleteRow(){
  modalDeleteBody.value = `Weet u het zeker dat u de registratie waarde voor het jaar ${currentEdit.value.year} wilt verwijderen?`
  modalDeleteOpen.value = true;
}

 async function onDeleteYearlyUsageDialog(resultValue){
  isSaving.value = true;
  modalDeleteOpen.value = false;
  if (resultValue == "DELETE"){
    const id = currentEdit.value.id;
    const response = await delRegisterYearUsage(props.registerId, id);
      switch (response.severity) {
        case severityCode.error:
          proxy.$toaster.error("Het verwijderen van de gebruikgegeven is mislukt! " + response.error);
          break;
      }
      if (response.severity <= severityCode.warning) {
          fetchData();
      }
      currentEdit.value = undefined;
    }
  isSaving.value = false;

}

async function saveRow() {
  await onSave();
}

function inEdit(id) {
  return id == "any" ? currentEdit.value && currentEdit.value.id.length > 0 : currentEdit.value && currentEdit.value.id == id;
}

const obsUsage = ref(null);

async function onSave() {
  isSaving.value = true;
  const valid = await obsUsage.value.validate();
  console.log("Register, onSave valid ", valid, obsUsage.value[0], obsUsage.value);
  if (valid) {
    let response = undefined;
    if (currentEdit.value.id === "new") {
      response = await addRegisterYearUsage(props.registerId, { year: usages.value[currentEdit.value.index].year, value: usages.value[currentEdit.value.index].value });
    } else {
      response = await patchRegisterYearUsage(props.registerId, usages.value[currentEdit.value.index].id, usages.value[currentEdit.value.index]);
    }
    console.log("Register, onSave ", response);
    if (response.severity <= severityCode.warning) {
      switch (response.severity) {
        // case severityCode.hint:
        // case severityCode.warning:
        //   proxy.$toaster.warning(response.message);
        //   break;
        case severityCode.error:
          proxy.$toaster.error("Bewaren van meter is mislukt! " + response.error);
          break;
      }
      if (response.severity <= severityCode.warning) {
        fetchData();
      }
    }
    currentEdit.value = undefined;
  }
  isSaving.value = false;
}
</script>

<template>
    <v-container fluid pa-0>
        <v-row class="overview-wrapper">
            <v-col cols="2">
                <header class="app-welcome-message">
                    <h4>Verbruikgegevens</h4>
                </header>
            </v-col>
            <v-col cols="1">
                <v-btn dark class="primary" @click="addUsage" :disabled="inEdit('any')">
                    <v-icon dark center>mdi-plus</v-icon>
                </v-btn>
            </v-col>
        </v-row>
        <ValidationObserver ref="obsUsage">
            <v-form>
                <v-data-table dense :headers="headers" hide-default-footer :items="usages">
                    <template v-slot:[`item.year`]="{ item }">
                        <span v-if="!inEdit(item.id)">
                            {{ item.year }}
                        </span>
                        <ValidationProvider v-else name="Jaar" rules="required:numeric|between:1950,2050" v-slot="{ errors }">
                            <v-text-field hide-details="auto" outlined persistent-placeholder dense v-model="item.year" :error-messages="errors" :readonly="false"></v-text-field>
                        </ValidationProvider>
                    </template>
                    <template v-slot:[`item.value`]="{ item }">
                        <span v-if="!inEdit(item.id)">
                            {{ item.value }}
                        </span>
                        <ValidationProvider v-else name="Waarde" rules="required:decimal:4|between:0,999999999.9999" v-slot="{ errors }">
                            <v-text-field hide-details="auto" outlined persistent-placeholder dense v-model="item.value" :error-messages="errors" :readonly="false"></v-text-field>
                        </ValidationProvider>
                    </template>

                    <template v-slot:[`item.modifiedDate`]="{ item }">
                        {{ formatDateUtcDT(item.modifiedDate) }}
                    </template>

                    <template v-slot:[`item.actions`]="{ item }">
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn :disabled="inEdit('any')" @click="editRow(item.id)" icon small plain v-bind="attrs" v-on="on">
                                    <v-icon small> mdi-pencil </v-icon>
                                </v-btn>
                            </template>
                            <span>Bewerken</span>
                        </v-tooltip>
                    </template>
                    <template v-slot:[`item.confirm`]="{ item }">
                        <v-tooltip v-if="inEdit(item.id)" bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn :disabled="!inEdit(item.id) || isSaving" @click="saveRow()" icon small plain v-bind="attrs" v-on="on">
                                    <v-icon small> mdi-checkbox-marked-circle </v-icon>
                                </v-btn>
                            </template>
                            <span>Opslaan</span>
                        </v-tooltip>
                    </template>
                    <template v-slot:[`item.cancel`]="{ item }">
                        <v-tooltip v-if="inEdit(item.id)" bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn :disabled="!inEdit(item.id) || isSaving" @click="cancelRow(item.id)" icon small plain v-bind="attrs" v-on="on">
                                    <v-icon small> mdi-cancel </v-icon>
                                </v-btn>
                            </template>
                            <span>Annuleren</span>
                        </v-tooltip>
                    </template>
                    <template v-slot:[`item.delete`]="{ item }">
                        <v-tooltip v-if="inEdit(item.id) && currentEdit.id !== 'new'" bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn :disabled="!inEdit(item.id) || isSaving" @click="deleteRow()" icon small plain v-bind="attrs" v-on="on">
                                    <v-icon small> mdi-delete </v-icon>
                                </v-btn>
                            </template>
                            <span>Verwijderen</span>
                        </v-tooltip>
                    </template>
                </v-data-table>
            </v-form>
        </ValidationObserver>


        <AlertOkCancel title="Verwijderen verbruiksgegevens" showDelete :body="modalDeleteBody" :persistent="false" :modalOpen="modalDeleteOpen" @onDialogResult="onDeleteYearlyUsageDialog" />

    </v-container>

</template>
