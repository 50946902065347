<script lang="ts" setup>
import { ref, computed, watch, getCurrentInstance } from "vue";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { getRegisterDetail, addRegister, patchRegister, severityCode } from "@/services/api/energy.api";
import useDateTimeFormatter from "@/composables/formatDateTime.js";
import router from "@/router/index";
import RegisterUsageYearlyDetails from "@/components/energy/RegisterUsageYearlyDetails.vue";
import DateInput from "@/components/general/DateInput.vue";
import { getRegisterTypes, getRegisterEnergyTypes, getRegisterUnitTypes, getRegisterSources } from "@/services/api/masterdata.api";

const emits = defineEmits(["update-register-reference", "update-register"]);

const { formatDateUtcDT } = useDateTimeFormatter();

const props = defineProps({
  registerId: String,
  addModus: {
    type: Boolean,
    default: false,
  },
});

const editMode = ref(false);
const register = ref({
  id: undefined,
  source: undefined,
  factor: 1,
});
const obs = ref(null);

const typeList = ref([]);
const energyTypeList = ref([]);
const unitTypeList = ref([]);
const registerSourcesList = ref([]);
const isSaving = ref(false);

const allowEdit = computed(() => props.addModus || editMode.value);
const disableSave = computed(() => (!props.addModus && !register.value.id) || isSaving.value);
const modDateFormatted = computed(() => formatDateUtcDT(register.value.modifiedDate));
const unitTypeDescription = computed(() => {
  const descr = unitTypeList.value.find((x) => x.key == register.value.unitTypeId);
  console.log("Register, unitTypeDescription ", descr);
  return descr ? descr.description : "";
});

watch(
  () => props.registerId,
  () => {
    if (!props.addModus) {
      fetchData();
    } else {
      getMasterDataRegisterTypes();
      getMasterDataEnergyTypes();
      getMasterDataUnitTypes();
      getMasterDataRegisterSources();
    }
  },
  { immediate: true }
);

async function fetchData() {
  const response = await getRegisterDetail(props.registerId);

  if (response && !response.error) {
    register.value = response.data;
    emits("update-register-reference", register.value.reference);
    typeList.value.splice(0);
    typeList.value.push({ key: register.value.typeId, description: register.value.typeDescription });
    energyTypeList.value.splice(0);
    energyTypeList.value.push({ key: register.value.energyTypeId, description: register.value.energyTypeDescription });
    unitTypeList.value.splice(0);
    unitTypeList.value.push({ key: register.value.unitTypeId, description: `${register.value.unitTypeName} (${register.value.unitTypeDescription})` });
    registerSourcesList.value.splice(0);
    registerSourcesList.value.push({ key: register.value.source, description: register.value.source });

    console.log("Register, fetchData ", register.value);
  } else {
    proxy.$toaster.error("Ophalen van de data is mislukt! " + response.error);
  }
}

async function getMasterDataRegisterTypes() {
  const types = await getRegisterTypes();
  typeList.value = types.data.registerTypes.filter((x) => x.source == null).map((x) => ({ key: x.id, description: x.descr }));
}

async function getMasterDataEnergyTypes() {
  const types = await getRegisterEnergyTypes();
  energyTypeList.value = types.data.energyTypes.map((x) => ({ key: x.id, description: x.descr }));
}

async function getMasterDataUnitTypes() {
  const types = await getRegisterUnitTypes();
  unitTypeList.value = types.data.unitTypes.map((x) => ({ key: x.id, description: `${x.name} (${x.descr})` }));
}

async function getMasterDataRegisterSources() {
  const sources = await getRegisterSources();
  console.log("Register, getRegisterSources ", sources);

  registerSourcesList.value = sources.data.keyDescList.map((x) => ({ key: x.key, description: x.description }));
}

function checkDateValid() {
  return { on: ["input"] };
}

async function onEdit() {
  getMasterDataRegisterTypes();
  getMasterDataEnergyTypes();
  getMasterDataUnitTypes();
  getRegisterSources();
  editMode.value = true;
}

async function onCancel() {
  if (props.addModus) {
    router.push({ name: "EU-Registers" });
  } else {
    editMode.value = false;
    fetchData();
  }
}

async function onSave() {
  isSaving.value = true;
  const valid = await obs.value.validate();
  if (valid) {
    let response = undefined;
    if (props.addModus) {
      response = await addRegister(register.value);
    } else {
      response = await patchRegister(register.value.id, register.value);
    }
    console.log("EnergyDataRegister, onSave ", response);
    switch (response.severity) {
      case severityCode.hint:
      case severityCode.warning:
        proxy.$toaster.warning(response.message);
        break;
      case severityCode.error:
        proxy.$toaster.error("Bewaren van meter is mislukt! " + response.message);
        break;
      default:
        proxy.$toaster.success("Gegevens van deze meter zijn opgeslagen.");
        break;
    }
    if (response.severity != severityCode.error) {
        if (props.addModus) {
          router.push({ name: "EU-Register", params: { registerId: response.data.id } });
        } else {
          editMode.value = false;
          emits("update-register");
        }
    }
  }
  isSaving.value = false;
}

//hack voor vue 2.7
const proxy = getCurrentInstance().proxy;
</script>

<template>
  <v-container fluid pa-0>
    <ValidationObserver ref="obs">
      <v-form>
        <v-container fluid pa-0>
          <v-row>
            <v-col>
              <masonry :cols="{ default: 2, 1264: 1 }" :gutter="12">
                <v-container class="boxed" fluid :class="{ noBorders: false }">
                  <header>Geldigheid</header>
                  <v-row>
                    <v-col>
                      <ValidationProvider name="Geldig vanaf" rules="date_between|required" v-slot="{ errors }" :mode="checkDateValid">
                        <date-input
                          v-model="register.validFrom"
                          label="Geldig vanaf"
                          persistent-placeholder
                          :errors="errors"
                          :outlined="allowEdit"
                          :class="{ noBorders: !allowEdit }"
                          :readonly="!allowEdit"
                        ></date-input>
                      </ValidationProvider>
                    </v-col>
                    <v-col>
                      <ValidationProvider name="Geldig tot" :rules="`date_between|date_after:${register.validFrom}`" v-slot="{ errors }" :mode="checkDateValid">
                        <date-input
                          v-model="register.validTill"
                          label="Geldig tot"
                          persistent-placeholder
                          :errors="errors"
                          :outlined="allowEdit"
                          :class="{ noBorders: !allowEdit }"
                          :readonly="!allowEdit"
                        ></date-input>
                      </ValidationProvider>
                    </v-col>
                  </v-row>
                </v-container>

                <v-container v-if="!addModus" class="boxed" :class="{ noBorders: false }">
                  <header>Aanpassing</header>
                  <v-row>
                    <v-col>
                      <v-text-field hide-details="auto" label="Bewerker" class="noBorders" persistent-placeholder v-model="register.modifiedBy" :readonly="true"></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-text-field hide-details="auto" label="Datum" class="noBorders" persistent-placeholder v-model="modDateFormatted" :readonly="true"></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </masonry>
              <masonry :cols="{ default: 3, 768: 2, 1264: 1 }" :gutter="12" :class="{ noBorders: false }">
                <v-container class="boxed">
                  <header>Algemene gegevens</header>
                  <v-row>
                    <v-col>
                      <ValidationProvider name="Omschrijving" rules="required" v-slot="{ errors }">
                        <v-text-field
                          hide-details="auto"
                          label="Omschrijving"
                          persistent-placeholder
                          v-model="register.description"
                          :error-messages="errors"
                          :outlined="allowEdit"
                          :class="{ noBorders: !allowEdit }"
                          :readonly="!allowEdit"
                        ></v-text-field>
                      </ValidationProvider>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col>
                      <ValidationProvider name="Type" rules="required" v-slot="{ errors }">
                        <v-select
                          hide-details="auto"
                          persistent-placeholder
                          label="Type"
                          :items="typeList"
                          item-text="description"
                          item-value="key"
                          v-model="register.typeId"
                          :error-messages="errors"
                          :outlined="allowEdit"
                          :class="{ noBorders: !allowEdit }"
                          :readonly="!allowEdit"
                        ></v-select>
                      </ValidationProvider>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <ValidationProvider name="EnergyType" rules="required" v-slot="{ errors }">
                        <v-select
                          hide-details="auto"
                          persistent-placeholder
                          label="Energietype"
                          :items="energyTypeList"
                          item-text="description"
                          item-value="key"
                          v-model="register.energyTypeId"
                          :error-messages="errors"
                          :outlined="allowEdit"
                          :class="{ noBorders: !allowEdit }"
                          :readonly="!allowEdit"
                        ></v-select>
                      </ValidationProvider>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <ValidationProvider name="UnitType" rules="required" v-slot="{ errors }">
                        <v-select
                          hide-details="auto"
                          persistent-placeholder
                          label="Eenheid"
                          :items="unitTypeList"
                          item-text="description"
                          item-value="key"
                          v-model="register.unitTypeId"
                          :error-messages="errors"
                          :outlined="allowEdit"
                          :class="{ noBorders: !allowEdit }"
                          :readonly="!allowEdit"
                        ></v-select>
                      </ValidationProvider>
                    </v-col>
                  </v-row>
                </v-container>

                <v-container class="boxed">
                  <header>Bron</header>
                  <v-row>
                    <v-col>
                      <v-switch
                        hide-details="auto"
                        label="Indicatief"
                        persistent-placeholder
                        v-model="register.isIndicative"
                        :outlined="allowEdit"
                        :class="{ noBorders: !allowEdit }"
                        :readonly="!allowEdit"
                      ></v-switch>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <ValidationProvider name="Bron" rules="required" v-slot="{ errors }">
                        <v-select
                          hide-details="auto"
                          label="Bron"
                          :items="registerSourcesList"
                          item-text="description"
                          item-value="key"
                          persistent-placeholder
                          v-model="register.source"
                          :error-messages="errors"
                          :outlined="allowEdit"
                          :class="{ noBorders: !allowEdit }"
                          :readonly="!allowEdit"
                        ></v-select>
                      </ValidationProvider>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <ValidationProvider name="Bronreferentie" rules="required" v-slot="{ errors }">
                        <v-text-field
                          hide-details="auto"
                          label="Bronreferentie"
                          persistent-placeholder
                          v-model="register.sourceReference"
                          :error-messages="errors"
                          :outlined="allowEdit"
                          :class="{ noBorders: !allowEdit }"
                          :readonly="!allowEdit"
                        ></v-text-field>
                      </ValidationProvider>
                    </v-col>
                  </v-row>
                </v-container>
                <v-container class="boxed">
                  <header>Notities</header>
                  <v-row>
                    <v-col>
                      <ValidationProvider name="Notities" rules="max:1000" v-slot="{ errors }">
                        <v-text-field
                          hide-details="auto"
                          persistent-placeholder
                          v-model="register.note"
                          :counter="1000"
                          :error-messages="errors"
                          :outlined="allowEdit"
                          :class="{ noBorders: !allowEdit }"
                          :readonly="!allowEdit"
                        ></v-text-field>
                      </ValidationProvider>
                    </v-col>
                  </v-row>
                </v-container>
              </masonry>
            </v-col>
          </v-row>
          <v-row mt-5>
            <v-col cols="auto" class="form-group" v-if="!allowEdit">
              <v-btn class="primary" v-on:click="onEdit()" :disabled="disableSave">
                <v-icon dark left>mdi-pencil</v-icon>
                Wijzigen
              </v-btn>
            </v-col>
            <v-col cols="auto" class="form-group" v-else>
              <v-btn class="primary" v-on:click="onSave()" :disabled="disableSave">
                <v-icon dark left>mdi-checkbox-marked-circle</v-icon>
                Opslaan
              </v-btn>
            </v-col>
            <v-col cols="auto" class="form-group" v-show="allowEdit">
              <v-btn v-on:click="onCancel()">
                <v-icon dark left>mdi-cancel</v-icon>
                Annuleren
              </v-btn>
            </v-col>
            <v-spacer></v-spacer>
          </v-row>
        </v-container>
      </v-form>
    </ValidationObserver>

    <register-usage-yearly-details v-if="!addModus" :registerId="register.id" :unitType="unitTypeDescription"></register-usage-yearly-details>
  </v-container>
</template>
